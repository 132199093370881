<template>
  <div class="button-group">
    <slot />
  </div>
</template>

<script>
export default {
  name: "base-form-button-group",
};
</script>

<style>
.button-group {
  grid-column: 1/-1;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}
</style>
