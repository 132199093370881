<template>
  <h1 class="base-form__title"><slot /></h1>
</template>

<script>
export default {
  name: "form-title",
};
</script>

<style>
.base-form__title {
  grid-column: 1/-1;

  height: 28px;

  text-align: center;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;

  color: #3a3b3c;
}
</style>
