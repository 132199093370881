<template>
  <label class="hamburger" for="hamburger">
    <input type="checkbox" id="hamburger" @click="handleClick" />
    <span></span>
    <span></span>
    <span></span>
  </label>
</template>

<script>
export default {
  name: "hamburger",
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    handleClick() {
      this.$emit("input", !this.value);
    },
  },
};
</script>

<style>
.hamburger {
  display: none;
  flex-direction: column;
  width: 50px;
  cursor: pointer;
}

.hamburger span {
  background: #fff;
  border-radius: 10px;
  height: 4px;
  margin: 4px 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.hamburger span:nth-of-type(1) {
  width: 50%;
}

.hamburger span:nth-of-type(2) {
  width: 100%;
}

.hamburger span:nth-of-type(3) {
  width: 75%;
}

.hamburger input[type="checkbox"] {
  display: none;
}

.hamburger input[type="checkbox"]:checked ~ span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotatez(45deg) translate(4px, 0px);
}

.hamburger input[type="checkbox"]:checked ~ span:nth-of-type(2) {
  transform-origin: top;
  transform: rotatez(-45deg);
}

.hamburger input[type="checkbox"]:checked ~ span:nth-of-type(3) {
  transform-origin: bottom;
  width: 50%;
  transform: translate(20px, -5px) rotatez(45deg);
}

@media screen and (max-width: 500px) {
  .hamburger {
    display: flex;
  }
}
</style>
