/* eslint-disable */
import RecruiterService from "../../services/RecruiterService.js";

const recruiter = {
  namespaced: true,
  state: {
    data: [],
    userRoles: [
      { value: "user", text: "Usuario", name: "ROLE_USER" },
      { value: "mod", text: "Moderador", name: "ROLE_MODERATOR" },
      { value: "admin", text: "Administrador", name: "ROLE_ADMIN" },
    ],
  },
  mutations: {
    SET_DATA: (state, data) => (state.data = data),
  },
  actions: {
    fetchAll: async ({ commit, state }) => {
      const response = await RecruiterService.fetchAll();
      commit("SET_DATA", response.data);
      state.userRoles = [
        { value: "user", text: "Usuario", name: "ROLE_USER" },
        { value: "mod", text: "Moderador", name: "ROLE_MODERATOR" },
        { value: "admin", text: "Administrador", name: "ROLE_ADMIN" },
      ];
      return response;
    },
    add: async ({}, { data }) => {
      const response = RecruiterService.add(data);
      return response;
    },
    editById: async ({}, { id, data }) => {
      const response = RecruiterService.editById(id, data);
      return response;
    },
    deleteById: async ({}, { id }) => {
      const response = RecruiterService.deleteById(id);
      return response;
    },
    usernameExists: async ({}, { data }) => {
      const response = await RecruiterService.usernameExists(data);
      return response;
    },
    emailExists: async ({}, { data }) => {
      const response = await RecruiterService.emailExists(data);
      return response;
    },
  },
  getters: {
    data: (state) => state.data,
    userRoles: (state) => state.userRoles,
  },
};

export default recruiter;
