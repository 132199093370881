import QuestionAnswer from "./QuestionAnswer";
import Statement from "./Statement";
export default class Question {
  constructor(id, questionStatements, answers, multipleChoice, q_group) {
    this.id = id;
    this.questionStatements = questionStatements.map(
      (el) => new Statement(el.id, el.description, el.statement)
    );
    this.answers = answers.map((el) => new QuestionAnswer(el.id, el.reply));
    this.multipleChoice = multipleChoice;
    this.q_group = q_group;
  }
}
