<template>
  <div class="input-group">
    <label :for="id" class="input-group__label">{{ label }}</label>

    <select
      :id="id"
      class="input-group__input-select"
      v-model="model"
      @blur="$emit('blur')"
    >
      <option value>Seleccione una opción</option>
      <option
        v-for="(option, index) in options"
        :key="index"
        :value="option.value"
        :selected="value === option.value ? true : false"
      >
        {{ option.text }}
      </option>
    </select>
    <p
      class="input-group__hint input-group__hint--error"
      v-if="errors.length != 0"
    >
      {{ errors }}
    </p>
  </div>
</template>

<script>
export default {
  name: "base-input-select",
  props: {
    value: {},
    label: {
      type: String,
      default: () => "",
    },
    placeholder: {
      type: String,
      default: () => "",
    },
    errors: {
      type: String,
      default: () => "",
    },
    id: {
      type: String,
      default: () => "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    model: "",
  }),
  updated() {
    this.model = this.value;
  },
  watch: {
    model(newValue) {
      this.$emit("input", newValue);
    },
  },
};
</script>

<style lang="css">
.input-group {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-content: left;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 33%;
}

.input-group__label {
  padding: 0 10px;

  font-weight: 600;
  font-size: 15px;
  line-height: 15px;

  color: #3a3b3c;
}

.input-group__input-select {
  width: 100%;
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;

  background: #ffffff;
  box-shadow: 0px 1px 3px 1px rgba(128, 128, 128, 0.25);
  border-radius: 8px;
  border: none;

  font-weight: 300;
  font-size: 14px;
  line-height: 16px;

  color: #aaaaaa;

  -webkit-appearance: none;
  appearance: none;
}

.input-group__input::placeholder {
  font-weight: 200;
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;

  color: #aaaaaa;
}

.input-select__group {
  position: relative;
}

.input-select__icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.input-group__hint {
  position: unset;
  text-align: left;
  font-size: 14px;
  line-height: 14px;
  padding: 0 10px;
}

.input-group__hint--error {
  color: red;
}
</style>
