import AuthService from "./../../services/AuthService";
const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: { email: undefined } };

/* eslint-disable */
const Auth = {
  namespaced: true,
  state: () => initialState,
  mutations: {
    LOGIN_SUCCESS(state, user) {
      console.log("mut user", user);
      state.status.loggedIn = true;
      state.user = user;
    },
    LOG_OUT(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
  },
  actions: {
    async authenticate({ commit }, { data }) {
      const res = await AuthService.login(data);
      if (res.status == 200) commit("LOGIN_SUCCESS", res.data);
      return res;
    },
    logout({ commit }) {
      AuthService.logout();
      commit("LOG_OUT");
    },
  },
  getters: {
    loggedIn: (state) => state.status.loggedIn,
  },
};

export default Auth;
