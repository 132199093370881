import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);
import Store from "../store/index";

const routes = [
  {
    path: "/quiz/resolve/:companyExternalId/:quizName",
    name: "quiz-start",
    component: Home,
  },
  {
    path: "/form",
    name: "form",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Form" */ "../views/Form.vue"),
  },
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Dashboard" */ "../views/Dashboard.vue"),
  },

  {
    path: "/description",
    name: "description",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Description" */ "../views/Description.vue"),
  },

  {
    path: "/instructions",
    name: "instructions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Quiz" */ "../views/Instructions.vue"),
  },

  {
    path: "/quiz",
    name: "quiz",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () => import(/* webpackChunkName: "Quiz" */ "../views/Quiz.vue"),
  },

  {
    path: "/quiz-end",
    name: "quiz-end",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
      import(/* webpackChunkName: "QuizEnd" */ "../views/QuizEnd.vue"),
  },

  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
  },

  {
    path: "/logout",
    beforeEnter(to, from, next) {
      Store.dispatch("Auth/logout");
      next({
        path: "/login",
      });
    },
    meta: { requiresAuth: true },
  },

  {
    path: "/test/results/:quizId",
    name: "tests-results",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
      import(
        /* webpackChunkName: "Login" */ "../views/manage/TestsResults.vue"
      ),
    meta: {
      layout: "admin-layout",
      requiresAuth: true,
    },
  },

  {
    path: "/test/result/:id",
    name: "test-result",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/manage/TestResult.vue"),
    meta: {
      layout: "admin-layout",
      requiresAuth: true,
    },
  },

  {
    path: "/users",
    name: "users",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/manage/Users.vue"),
    meta: {
      layout: "admin-layout",
      requiresAuth: true,
    },
  },

  {
    path: "/user/create",
    name: "create-user",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
      import(
        /* webpackChunkName: "create-user" */ "../views/manage/CreateUser.vue"
      ),
    meta: {
      layout: "admin-layout",
      requiresAuth: true,
    },
  },

  {
    path: "/user/edit/:userId",
    name: "edit-user",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
      import(
        /* webpackChunkName: "edit-user" */ "../views/manage/EditUser.vue"
      ),
    meta: {
      layout: "admin-layout",
      requiresAuth: true,
    },
  },

  {
    path: "*",
    name: "not-found",
    props: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.

    component: () =>
      import(/* webpackChunkName: "NotFound" */ "../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!Store.getters["Auth/loggedIn"]) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});

export default router;
