export default class QuizResult {
  constructor(
    quizId,
    companyExternalId,
    candidate,
    quizDuration,
    chosenQuestionsAnswers
  ) {
    this.quizId = quizId;
    this.companyExternalId = companyExternalId;
    this.candidate = candidate;
    this.quizDuration = quizDuration;
    this.chosenQuestionsAnswers = chosenQuestionsAnswers;
  }
}
