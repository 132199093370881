import Statement from "./Statement";
import Question from "./Question";

export default class Quiz {
  constructor(
    id,
    name,
    description,
    instructions,
    quizStatements,
    questions,
    sampleQuestions,
    maxDuration
  ) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.instructions = instructions;
    this.quizStatements = quizStatements.map(
      (el) => new Statement(el.id, null, el.statement)
    );
    this.questions = questions.map(
      (el) =>
        new Question(
          el.id,
          el.questionStatements,
          el.answers,
          el.multipleChoice,
          el.q_group
        )
    );
    this.sampleQuestions = sampleQuestions.map(
      (el) =>
        new Question(
          el.id,
          el.questionStatements,
          el.answers,
          el.multipleChoice,
          el.q_group
        )
    );
    this.maxDuration = maxDuration;
  }
}
