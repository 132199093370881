<template>
  <nav class="navbar">
    <hamburger v-model="hamburgerStatus" />
    <div v-show="showMenu" class="navbar-group">
      <ul class="navbar-items">
        <li class="navbar-item">
          <a class="navbar-item-title">
            Resultados <font-awesome-icon icon="angle-down" />
          </a>
          <ul class="navbar-menu-list">
            <li class="navbar-menu-list__item">
              <a href="/test/results/1">
                Test IC
              </a>
            </li>
            <li class="navbar-menu-list__item">
              <a href="/test/results/2">
                Test NEO
              </a>
            </li>
            <li class="navbar-menu-list__item">
              <a href="/test/results/3">
                Test DISC
              </a>
            </li>
          </ul>
        </li>
        <li class="navbar-item">
          <router-link to="/users" class="navbar-item-title">
            Gestión de usuarios <font-awesome-icon icon="angle-down" />
          </router-link>
          <ul class="navbar-menu-list">
            <li class="navbar-menu-list__item">
              <a href="/user/create">
                Agregar usuario
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <a class="navbar-logout" href="/logout">
        Cerrar Sesión <font-awesome-icon icon="power-off" />
      </a>
    </div>
  </nav>
</template>

<script>
import Hamburger from "../components/Hamburger";

export default {
  name: "navbar",
  components: {
    Hamburger,
  },
  data() {
    return {
      hamburgerStatus: false,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    handleItemClick(id) {
      this.$router.push({ name: "tests-results", params: { id: id } });
    },
  },
  computed: {
    showMenu() {
      if (this.windowWidth <= 500) {
        if (this.hamburgerStatus) return true;
        return false;
      } else return true;
    },
  },
};
</script>

<style>
/* inicio - navbar */
.navbar {
  width: 100%;
  max-width: 828px;

  margin-top: 40px;
  padding: 18px 40px;

  background: #ff8600;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
}

.navbar-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.navbar-items,
.navbar-logout {
  color: #fff;
  font-weight: 200;
  text-decoration: none;
}
.navbar-logout {
  cursor: pointer;
}

.navbar-items {
  list-style-type: none;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.navbar-item {
  position: relative;
}

.navbar-item-title {
  text-decoration: none;
  color: #fff;
}

.navbar-menu-list {
  list-style: none;
  border-radius: 10px;
  background-color: #ff8600;
  width: 100%;
  display: none;
  position: absolute;
  box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.25);
}

.navbar-menu-list__item {
  padding: 10px 10px;
  font-size: 13px;
  border-bottom: 1px solid #fff;
  opacity: 0.7;
  cursor: pointer;
}

.navbar-menu-list__item a {
  color: #fff;
  text-decoration: none;
}

.navbar-menu-list__item:hover {
  opacity: 1;
}

.navbar-menu-list__item:last-child {
  border: none;
}

.navbar-item:hover .navbar-menu-list {
  display: block;
}

.navbar-item:nth-child(2) {
  margin-left: 20px;
}

@media screen and (max-width: 828px) {
  .navbar {
    border-radius: unset;
  }
}

@media screen and (max-width: 500px) {
  .navbar-item {
    position: relative;
  }

  .navbar-menu-list {
    background-color: #ff8600;
    width: 100%;
    position: relative;
    box-shadow: none;
  }

  .navbar-menu-list__item:last-of-type {
    border-bottom: 1px solid #fff;
  }

  .navbar-group {
    flex-direction: column;
  }

  .navbar-items {
    flex-direction: column;
  }

  .navbar-item,
  .navbar-logout {
    margin-top: 17px;
  }

  .navbar-item:first-of-type {
    margin-top: 0;
  }

  .navbar-item:nth-of-type(2) {
    margin-left: 0;
  }
}

/* fin - navbar */

@media screen and (max-width: 650px) {
  .navbar {
    margin-top: 10px;
  }
}
</style>
