<template>
  <div @click="$router.go(-1)" class="go-back">
    <font-awesome-icon icon="chevron-left" />
    <span class="go-back__text">Volver atras</span>
  </div>
</template>

<script>
export default {
  name: "base-go-back",
};
</script>

<style>
.go-back {
  align-self: flex-start;
  display: flex;
  gap: 10px;
  margin: 20px 20px;
  cursor: pointer;
}
</style>
