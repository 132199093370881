/* eslint-disable */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import "./assets/css/style.css";
import "./assets/css/formulario.css";
import "./assets/css/dashboard.css";

import { RegisterGlobalComponents } from "./components/GlobalComponents.js";
RegisterGlobalComponents(Vue);

import AdminLayout from "./layouts/AdminLayout.vue";
Vue.component("admin-layout", AdminLayout);

import ContentWrapper from "./components/ContentWrapper";
Vue.component("content-wrapper", ContentWrapper);

import VueSweetAlert from "./plugins/VueSweetAlert";
import VClipboard from "./plugins/v-clipboard";
import { FontAwesome } from "./plugins/FontAwesome";
FontAwesome(Vue);

import VueProgressBar from "vue-progressbar";
const options = {
  color: "#a7c5eb",
  failedColor: "red",
  height: "2px",
  position: "top",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300,
  },
};
Vue.use(VueProgressBar, options);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
