import { HttpClient } from "./HttpClient.js";

import Quiz from "../models/Quiz";
import LevelStudy from "../models/LevelStudy";

export default class QuizService {
  static #httpClient = new HttpClient();

  static async fetchQuiz(name, companyExternalId) {
    const res = await this.#httpClient.httpGet(
      "/api/quiz/" + companyExternalId + "/" + name
    );

    const { data } = res;

    const quiz = new Quiz(
      data.id,
      data.name,
      data.description,
      data.instructions,
      data.quizStatements,
      data.questions,
      data.sampleQuestions,
      data.maxDuration
    );

    res.data = quiz;

    return res;
  }

  static async fetchQuizResultsByQuizId(id) {
    return await this.#httpClient.httpGet("/api/quiz/" + id + "/results");
  }

  static async fetchQuizResultsById(id) {
    return await this.#httpClient.httpGet("/api/quiz/result/" + id);
  }

  static async submitQuiz(data) {
    return await this.#httpClient.httpPost("/api/quiz/submit", data);
  }

  static async fetchLevelStudy() {
    const res = await this.#httpClient.httpGet("/api/level-study/all");
    res.data = res.data.map((el) => new LevelStudy(el.id, el.name));
    return res;
  }

  static async blockCandidate(data) {
    return await this.#httpClient.httpPost(
      "/api/quiz/blocked/candidate/add",
      data
    );
  }

  static async checkIfCandidateIsBlocked(data) {
    return await this.#httpClient.httpPost(
      "/api/quiz/blocked/candidate/check",
      data
    );
  }
}
