<template>
  <div class="base-table">
    <section>
      <div>
        <h1>
          <template v-if="!loading">
            {{ tableTitle }}
          </template>
          <template v-else>
            Obteniendo datos...
          </template>
        </h1>
        <form>
          <input
            type="text"
            placeholder="Filtrar contenido...."
            v-model="search"
          />
          <button type="submit" class="search_btn">
            Buscar <font-awesome-icon icon="search" />
          </button>
          <base-icon-button
            v-if="showAddBtn"
            icon="plus"
            bgColor="#00b100"
            @click="handleAddClick"
          ></base-icon-button>
        </form>
      </div>
      <table>
        <thead>
          <tr>
            <th v-for="(header, index) of headers" :key="index">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-if="loading">
            <td :colspan="headersLength" style="text-align: center;">
              Obteniendo datos...
            </td>
          </template>
          <template v-else-if="dataHasData">
            <slot name="content" v-bind:rows="filteredData"></slot>
          </template>
          <template v-else>
            <tr>
              <td :colspan="headersLength" style="text-align: center;">
                Sin datos...
              </td>
            </tr>
          </template>
        </tbody>
      </table>

      <tfoot>
        <button @click="prevPage">
          <font-awesome-icon icon="chevron-left" />
        </button>
        <button
          v-for="(pag, index) in paginationSize"
          :key="index"
          @click="selectPage(pag)"
        >
          {{ pag }}
        </button>
        <button @click="nextPage">
          <font-awesome-icon icon="chevron-right" />
        </button>
      </tfoot>
    </section>
  </div>
</template>

<script>
import BaseIconButton from "./BaseIconButton";

export default {
  name: "my-table",
  components: {
    BaseIconButton,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
    tableTitle: {
      type: String,
      default: () => "Sin titulo",
    },
    showAddBtn: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      maxRowsPerPage: 3,
      selectedPage: 1,
      search: "",
    };
  },
  computed: {
    headersLength() {
      return this.headers.length + "";
    },
    dataHasData() {
      return this.filteredData.length > 0;
    },
    paginationSize() {
      if (this.searchData.length > 0) {
        return Math.ceil(this.searchData.length / this.maxRowsPerPage);
      }
      return Math.ceil(this.data.length / this.maxRowsPerPage);
    },
    searchData() {
      let searchData = [];
      if (this.search) {
        searchData = this.data.filter((el) => {
          let someValueMatch = false;
          for (const item in el) {
            const valueMatch = el[item]
              .toString()
              .toLowerCase()
              .includes(this.search.toLowerCase());

            if (valueMatch) {
              someValueMatch = true;
              break;
            }
          }
          if (someValueMatch) return el;
        });
      }
      return searchData;
    },
    filteredData() {
      const startIndex = (this.selectedPage - 1) * this.maxRowsPerPage;
      const limit = this.selectedPage * this.maxRowsPerPage;

      if (this.search.length > 0 && this.searchData.length > limit) {
        return this.searchData.slice(startIndex, limit);
      } else if (this.search.length > 0) {
        return this.searchData;
      }
      return this.data.slice(startIndex, limit);
    },
  },
  methods: {
    selectPage(page) {
      this.selectedPage = page;
    },
    nextPage() {
      if (this.selectedPage < this.paginationSize) this.selectedPage += 1;
    },
    prevPage() {
      if (this.selectedPage > 1) this.selectedPage -= 1;
    },
    handleAddClick() {
      this.$emit("add-clicked");
    },
  },
};
</script>

<style>
/* incio - table header */
.base-table section div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
}

.base-table section div form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.base-table section div form input {
  width: 170px;
  height: 21px;

  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #aaaaaa;
  border: none;
}

.base-table section div form input::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #aaaaaa;
}

.search_btn {
  width: 98px;
  height: 21px;
  margin-left: 10px;

  background: #ff8600;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #ffffff;
  border: none;
}

@media screen and (max-width: 740px) {
  .base-table section div {
    border-bottom: 1px solid #000;
  }

  .base-table section div h1 {
    margin-right: 10px;
  }
}

@media screen and (max-width: 500px) {
  .base-table section div {
    flex-direction: column;
  }

  .base-table section div form {
    margin-top: 15px;
  }

  .base-table section div h1 {
    margin-right: 0;
  }
}
/* fin - table header */

/* inicio section - table */
.base-table section {
  width: 100%;
  max-width: 809px;
  height: auto;
  background: #ffffff;
}

.base-table section table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  text-align: left;
  padding: 8px;
}

th {
  border-bottom: 1px solid #000000;
  border-top: 1px solid #000000;
  font-weight: 600;
}

tr {
  border-bottom: 1px solid #aaaaaa;
}

tr:nth-child(even) {
  background-color: #f4f4f4;
}

tr:last-of-type {
  border-bottom: 1px solid #000000;
}

@media screen and (max-width: 740px) {
  .base-table section {
    border-radius: 0;
  }
  .base-table section table thead {
    display: none;
  }

  .base-table section table td {
    display: block;
    text-align: center;
  }

  .base-table section table td::before {
    display: block;
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
  }
}

/* fin section - table */

/* inicio - table footer */
.base-table section tfoot {
  margin-right: 20px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.base-table section tfoot button {
  width: 20px;
  height: 20px;
  margin: 3px;

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: #ffffff;
  border-radius: 5px;
  border: none;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
}

@media screen and (max-width: 740px) {
  .base-table section tfoot {
    padding-bottom: 20px;
  }
}
/* fin - table footer */
</style>
