<template>
  <div id="app">
    <component :is="$route.meta.layout || 'div'">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener("beforeunload", () => {
      this.$store.dispatch("QuizTimer/clearQuizTimer");
      this.$store.dispatch("Quiz/restoreToDefaultValues");
    });
  },
};
</script>

<style>
.wrapper_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-center {
  text-align: center;
}
</style>
