<template>
  <div class="home">
    <img class="img__body" :src="imgBody" alt="" />
    <div class="container__logo">
      <img class="logo__clear" :src="imgLogo" alt="" />
    </div>

    <div class="container__flex">
      <h1 class="title__home">Sistema de Evaluación</h1>
      <h2 class="subtitle__home">
        Habilidades cognitivas, competencias y personalidad
      </h2>

      <button type="button" class="btn__home" @click="handleStart">
        COMENZAR
      </button>
    </div>

    <loading :active="isLoading" :is-full-page="true" :opacity="0.9">
      <template slot="after">
        <br />Obteniendo información y preguntas del cuestionario <br />
        Espere un momento...
      </template>
    </loading>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      imgBody: require("@/assets/img/clear people sin letras.png"),
      imgLogo: require("@/assets/img/clear people naranja (1).png"),
      isLoading: true,
    };
  },
  async created() {
    const { companyExternalId, quizName } = this.$route.params;

    this.isLoading = true;
    let res = await this.$store.dispatch("Quiz/fetchQuiz", {
      name: quizName,
      companyExternalId: companyExternalId,
    });
    console.log("res", res);
    console.log(
      "typeof res.data === 'undefined'",
      typeof res.data === "undefined"
    );
    console.log(
      "typeof res.status === 'undefined'",
      typeof res.status === "undefined"
    );

    if (typeof res.data === "undefined") {
      res = await this.$store.dispatch("Quiz/fetchQuiz", {
        name: quizName,
        companyExternalId: companyExternalId,
      });
      console.log("segundo intento res", res);
    }

    this.isLoading = false;

    if (res.data === "quiz not found") {
      this.$router.push({
        name: "not-found",
        params: { text: "Test no encontrado" },
      });
    }

    if (res.data === "company not found") {
      this.$router.push({
        name: "not-found",
        params: { text: "Empresa no encontrada" },
      });
    }
  },
  methods: {
    handleStart() {
      this.$router.push("/form");
    },
  },
};
</script>

<style>
.vld-overlay .vld-icon,
.vld-parent {
  text-align: center;
}
</style>
