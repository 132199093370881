import { HttpClient } from "./HttpClient";

export default class CompanyService {
  static #httpClient = new HttpClient();

  static async fetchAll() {
    return await this.#httpClient.httpGet("/api/company/all");
  }

  //   static async fetchById(id) {
  //     return await this.#httpClient.httpGet(`/api/company/${id}`);
  //   }

  //   static async add(data) {
  //     return await this.#httpClient.httpPost("/api/company/add", data);
  //   }

  //   static async editById(id, data) {
  //     return await this.#httpClient.httpPut(`/api/company/${id}`, data);
  //   }

  //   static async deleteById(id) {
  //     return await this.#httpClient.httpDelete(`/api/company/${id}`);
  //   }
}
