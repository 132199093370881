/* eslint-disable */
import Store from "../index.js";
const QuizTimer = {
  namespaced: true,
  state: () => ({
    interval: null,
    time: "00:00",
    duration: 0,
  }),
  mutations: {
    SET_TIME: (state, time) => (state.time = time),
    SET_DURATION: (state, duration) => (state.duration = duration),
    ADD_SECOND_DURATION: (state) => (state.duration += 1),
    SET_INTERVAL: (state, interval) => (state.interval = interval),
  },
  actions: {
    startQuizCountDown: ({ commit, state }, duration) => {
      commit("SET_DURATION", 0);

      duration = 60 * duration;
      let timer = duration,
        minutes,
        seconds;

      if (state.interval === null) {
        commit(
          "SET_INTERVAL",
          setInterval(() => {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            commit("SET_TIME", minutes + ":" + seconds);
            commit("ADD_SECOND_DURATION");

            if (--timer < 0) Store.dispatch("Quiz/submitQuiz");
            //if (--timer < 0) console.log("tiempo acabado");
          }, 1000)
        );
      }
    },
    clearQuizTimer: ({ state, commit }) => {
      clearInterval(state.interval);
      commit("SET_TIME", "00:00");
      commit("SET_INTERVAL", null);
    },
  },
  getters: {
    getTime: (state) => state.time,
    getDuration: (state) => (state.duration / 60).toFixed(2),
  },
};

export default QuizTimer;
