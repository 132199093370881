import axios from "axios";

axios.defaults.baseURL = "https://questionary-online-v2.herokuapp.com";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "application/json";

export class HttpClient {
  async httpGet(path) {
    try {
      const response = await axios.get(path);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async httpPost(path, data) {
    try {
      const response = await axios.post(path, data);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async httpPut(path, data) {
    try {
      const response = await axios.put(path, data);
      return response;
    } catch (error) {
      return error.response;
    }
  }

  async httpDelete(path) {
    try {
      const response = await axios.delete(path);
      return response;
    } catch (error) {
      return error.response;
    }
  }
}
