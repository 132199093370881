export default class Candidate {
  constructor(
    rut,
    name,
    lastname,
    surname,
    positionApply,
    email,
    phonenumber,
    levelStudyId,
    career,
    sexo
  ) {
    this.rut = rut;
    this.name = name;
    this.lastname = lastname;
    this.surname = surname;
    this.positionApply = positionApply;
    this.email = email;
    this.phonenumber = phonenumber;
    this.levelStudyId = levelStudyId;
    this.career = career;
    this.sexo = sexo;
  }
}
