import { HttpClient } from "./HttpClient";

export default class AuthService {
  static #httpClient = new HttpClient();

  static async login(data) {
    const res = await this.#httpClient.httpPost("/api/auth/signin", data);
    return res;
  }

  static logout() {
    localStorage.removeItem("user");
  }
}
