<template>
  <p class="base-form__detail" :style="{ 'border-color': borderColor }">
    {{ text }}
  </p>
</template>

<script>
export default {
  name: "base-form-divider",
  props: {
    text: {
      type: String,
    },
    borderColor: {
      type: String,
      default: () => "#999999",
    },
  },
};
</script>

<style>
.base-form__detail {
  position: unset;
  text-align: left;
  grid-column: 1/-1;

  padding-left: 10px;

  border-bottom: 1px solid #999999;

  font-size: 16px;
  line-height: 18px;
  font-weight: 600;

  color: #3a3b3c;
}
</style>
