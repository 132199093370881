import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import QuizTimer from "./modules/quizTimer";
import Quiz from "./modules/quiz";
import Recruiter from "./modules/recruiter";
import Company from "./modules/company";
import Auth from "./modules/Auth";

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    Quiz,
    QuizTimer,
    Recruiter,
    Company,
    Auth,
  },
});
