<template>
  <button
    class="icon_button"
    :style="{ background: bgColor }"
    v-on="$listeners"
    v-bind="$attrs"
    type="button"
  >
    <font-awesome-icon :icon="icon" :style="{ color: color }" />
  </button>
</template>

<script>
export default {
  name: "base-icon-button",
  props: {
    icon: {
      type: String,
    },
    color: {
      type: String,
      default: () => "white",
    },
    bgColor: {
      type: String,
    },
  },
};
</script>

<style>
.icon_button {
  width: 30px;
  height: 30px;

  border-radius: 50%;
  border: none;

  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  transition: box-shadow 300ms ease-in-out;

  cursor: pointer;
}

.icon_button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
</style>
