<template>
  <form class="base-form" v-on="$listeners" v-bind="$attrs"><slot /></form>
</template>

<script>
export default {
  name: "base-form",
};
</script>

<style>
.base-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  padding: 30px 25px;
}

@media screen and (max-width: 650px) {
  .base-form {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    padding: 30px 25px;
  }
}

@media screen and (max-width: 430px) {
  .base-form {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 30px 0px;
    margin: 0 10px;
  }
}
</style>
