<template>
  <div id="admin-layout">
    <logo />

    <navbar />

    <router-view></router-view>
  </div>
</template>

<script>
import Logo from "./Logo.vue";
import Navbar from "./Navbar.vue";

export default {
  name: "admin-layout",
  components: {
    Navbar,
    Logo,
  },
};
</script>

<style scoped>
#admin-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
