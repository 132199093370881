import { HttpClient } from "./HttpClient";

export default class RecruiterService {
  static #httpClient = new HttpClient();

  static async fetchAll() {
    return await this.#httpClient.httpGet("/api/recrutier/all");
  }

  static async fetchById(id) {
    return await this.#httpClient.httpGet(`/api/recrutier/${id}`);
  }

  static async add(data) {
    return await this.#httpClient.httpPost("/api/recrutier/add", data);
  }

  static async editById(id, data) {
    return await this.#httpClient.httpPut(`/api/recrutier/${id}`, data);
  }

  static async deleteById(id) {
    return await this.#httpClient.httpDelete(`/api/recrutier/${id}`);
  }

  static async usernameExists(data) {
    return await this.#httpClient.httpPost(
      "/api/recrutier/username/exists",
      data
    );
  }

  static async emailExists(data) {
    return await this.#httpClient.httpPost("/api/recrutier/email/exists", data);
  }
}
