<template>
  <div class="content-wrapper">
    <slot name="top" />
    <section
      class="content-wrapper__content"
      :class="paddingY0 ? 'content-wrapper--pading-y-0' : ''"
    >
      <slot />
    </section>
  </div>
</template>

<script>
export default {
  name: "content-wrapper",
  props: {
    paddingY0: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style>
.content-wrapper {
  width: 100%;
  max-width: 809px;
}

.content-wrapper__content {
  width: 100%;
  max-width: 809px;
  height: auto;
  margin: 26px 0;
  padding: 20px 20px;
  padding-bottom: 10px;

  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.content-wrapper--pading-y-0 {
  padding: 0 20px;
}

@media screen and (max-width: 650px) {
  .content-wrapper__content {
    border-radius: 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 430px) {
}
</style>
