/* eslint-disable */
import Store from "../index.js";
import Router from "../../router";

import QuizService from "../../services/QuizService.js";
import QuizModel from "../../models/Quiz.js";
import QuizResult from "../../models/QuizResult.js";
import Candidate from "../../models/Candidate.js";
import ChosenQuestionAnswer from "../../models/ChosenQuestionAnswer";
import chosenQuestionAnswer from "../../models/ChosenQuestionAnswer";
import BlockCandidate from "../../models/BlockCandidate";

const Quiz = {
  namespaced: true,
  state: () => ({
    quiz: new QuizModel(null, null, null, null, [], [], [], null),
    companyExternalId: "",
    levelStudies: [],
    candidate: new Candidate("", "", "", "", "", "", "", "", "", ""),
    quizResult: new QuizResult("", "", {}, 5, []),
    chosenQuestionsAnswers: [],
    skipQuestion: false,
  }),
  mutations: {
    SET_QUIZ: (state, quiz) => (state.quiz = quiz),
    SET_LEVEL_STUDIES: (state, levelStudies) =>
      (state.levelStudies = levelStudies),
    SET_QUIZ_RESULT: (state, quizResult) => (state.quizResult = quizResult),
    SET_CANDIDATE: (state, candidate) => (state.candidate = candidate),
    ADD_CHOSEN_QUESTION_ANSWERS: (state, chosenQuestionAnswers) =>
      state.chosenQuestionsAnswers.push(...chosenQuestionAnswers),
    SET_SKIP_QUESTION: (state, skip) => (state.skipQuestion = skip),
    SET_COMPANY_EXTERNAL_ID: (state, id) => (state.companyExternalId = id),
    SET_VALUES_TO_DEFAULT(state) {
      state.chosenQuestionsAnswers = [];
      state.quizResult = new QuizResult("", "", {}, 5, []);
    },
  },
  actions: {
    async autoCompleteMissingAnswers({ commit, state }) {
      let missingAnswers = [];
      state.quiz.questions.forEach((question) => {
        const isQuestionAnswered = state.chosenQuestionsAnswers.some(
          (el) => question.id === el.questionId
        );

        const skipAnswerId = 5;
        if (!isQuestionAnswered)
          missingAnswers.push(
            new chosenQuestionAnswer(question.id, skipAnswerId)
          ); //
      });

      commit("ADD_CHOSEN_QUESTION_ANSWERS", missingAnswers);
    },
    async fetchQuiz({ commit }, { name, companyExternalId }) {
      const res = await QuizService.fetchQuiz(name, companyExternalId);
      commit("SET_QUIZ", res.data);
      commit("SET_COMPANY_EXTERNAL_ID", companyExternalId);
      console.log(res);
      return res;
    },
    async fetchQuizResultsByQuizId({}, { quizId }) {
      const res = await QuizService.fetchQuizResultsByQuizId(quizId);
      return res;
    },
    async fetchQuizResultsById({}, { id }) {
      const res = await QuizService.fetchQuizResultsById(id);
      return res;
    },
    async submitQuiz({ commit, state, dispatch }) {
      Store.dispatch("QuizTimer/clearQuizTimer");

      dispatch("autoCompleteMissingAnswers");

      commit(
        "SET_QUIZ_RESULT",
        new QuizResult(
          state.quiz.id,
          state.companyExternalId,
          state.candidate,
          Store.getters["QuizTimer/getDuration"],
          state.chosenQuestionsAnswers
        )
      );

      const res = await QuizService.submitQuiz(state.quizResult);

      commit("SET_VALUES_TO_DEFAULT");

      Router.push("quiz-end");
    },
    async blockCandidate({ commit, state }) {
      Store.dispatch("QuizTimer/clearQuizTimer");

      commit(
        "SET_QUIZ_RESULT",
        new QuizResult(state.quiz.id, state.candidate, null, null)
      );

      const res = await QuizService.blockCandidate(state.quizResult);

      const { status } = res;
      if (status == 200) Router.push("quiz-blocked");
    },
    async fetchLevelStudies({ commit }) {
      const res = await QuizService.fetchLevelStudy();
      commit("SET_LEVEL_STUDIES", res.data);
    },
    async checkCandidateIsBlocked({ state }) {
      const res = await QuizService.checkIfCandidateIsBlocked(
        new BlockCandidate(state.quiz.id, state.candidate.email)
      );

      if (res.data.blocked) Router.push("quiz-blocked");
    },
    addCandidate: ({ commit }, candidate) => commit("SET_CANDIDATE", candidate),
    addChosenQuestionAnswers: ({ commit }, { questionId, answers }) => {
      let questionAnswers = answers.map(
        (el) => new ChosenQuestionAnswer(questionId, el)
      );
      commit("ADD_CHOSEN_QUESTION_ANSWERS", questionAnswers);
    },
    restoreToDefaultValues({ commit }) {
      commit("SET_VALUES_TO_DEFAULT");
    },
  },
  getters: {
    getQuiz: (state) => state.quiz,
    getLevelStudies: (state) => state.levelStudies,
    getSkipQuestion: (state) => state.skipQuestion,
  },
};

export default Quiz;
