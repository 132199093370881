<template>
  <button
    class="base-button"
    :style="{ backgroundColor: bgColor }"
    :type="submit ? 'submit' : 'button'"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <template v-if="!loading">
      <div class="base-button__content">
        <slot />
        <font-awesome-icon v-if="appendIcon != ''" :icon="appendIcon" />
      </div>
    </template>
    <template v-else>
      <span
        class="base-button--loading"
        role="status"
        aria-hidden="true"
      ></span>
    </template>
  </button>
</template>

<script>
export default {
  name: "base-button",
  props: {
    submit: {
      type: Boolean,
      default: () => false,
    },
    bgColor: {
      type: String,
      default: () => "#808080",
    },
    text: {
      type: String,
    },
    appendIcon: {
      type: String,
      default: () => "",
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style lang="css">
.base-button {
  width: 178px;
  height: 38px;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border: none;

  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #fff;

  cursor: pointer;

  transition: 0.3s all ease;
  position: relative;
}

.base-button:disabled {
  opacity: 0.6;
}

.base-button__content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.base-button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
</style>
