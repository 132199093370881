<template>
  <img
    class="base-logo"
    src="@/assets/img/clear people naranja (1).png"
    alt="logo"
  />
</template>

<script>
export default {
  name: "logo",
};
</script>

<style>
/* inicio - img */
.base-logo {
  width: 130px;
  height: 130px;
  margin-top: 55px;
}
/* fin - img */

@media screen and (max-width: 650px) {
  .base-logo {
    width: 90px;
    height: 90px;
    margin-top: 20px;
  }
}
</style>
