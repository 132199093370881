import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronLeft,
  faChevronRight,
  faSearch,
  faPowerOff,
  faAngleDown,
  faPencilAlt,
  faTrashAlt,
  faFileAlt,
  faTimesCircle,
  faSave,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faSearch);
library.add(faPowerOff);
library.add(faAngleDown);
library.add(faPencilAlt);
library.add(faTrashAlt);
library.add(faFileAlt);
library.add(faTimesCircle);
library.add(faSave);
library.add(faPlus);

export function FontAwesome(vm) {
  vm.component("font-awesome-icon", FontAwesomeIcon);
}
