/* eslint-disable */
import CompanyService from "../../services/CompanyService";

const company = {
  namespaced: true,
  state: {
    data: [],
  },
  mutations: {
    SET_DATA: (state, data) => (state.data = data),
  },
  actions: {
    fetchAll: async ({ commit }) => {
      const response = await CompanyService.fetchAll();
      commit("SET_DATA", response.data);
      return response;
    },
    // add: async ({}, { data }) => {
    //   const response = CompanyService.add(data);
    //   return response;
    // },
    // editById: async ({}, { id, data }) => {
    //   const response = CompanyService.editById(id, data);
    //   return response;
    // },
    // deleteById: async ({}, { id }) => {
    //   const response = CompanyService.deleteById(id);
    //   return response;
    // },
  },
  getters: {
    data(state) {
      return state.data;
    },
  },
};

export default company;
